import React, { Component } from 'react';
import constants from './components/Constants';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLogoutUser, switchMenu } from './redux/actions';
import Signup from './components/Signup';
import Login from './components/Login';
import Forgot from './components/Forgot';
import "./App.css";
let classLogin = "show";
let classSignup = "hide";
let classForgot = "hide";
let marginTop = -(168 / 2);
class App extends Component {
  handleClick = event => {
    event.preventDefault()
    this.props.getLogoutUser()
  }
  state = {
    show_contacts: false,
  }
  contacts_togle = () => {
    this.setState({
      show_contacts: !this.state.show_contacts
    })
  }
  signupClick = () => {
    classLogin = "hide";
    classSignup = "show";
    classForgot = "hide";
    marginTop = 0;
    this.forceUpdate()
  }
  loginClick = () => {
    classLogin = "show";
    classSignup = "hide";
    classForgot = "hide";
    marginTop = -(168 / 2);
    this.forceUpdate()
  }
  forgotClick = () => {
    classLogin = "hide";
    classSignup = "hide";
    classForgot = "show";
    marginTop = -(382 / 2);
    this.forceUpdate()
  }
  render() {
    return (
      <div className="root_menu">
        <div className="App">
          {this.props.currentUser?.access_token
            ? (
              <nav className="page_navigations">
                <Link to={`/${constants.localePath}dashboard`} label="Dashboard" className="dbbutton">
                  <span>{localStorage.getItem("email")}</span>
                </Link>
                <ul>
                  <li>
                    <div className="btn-wrap">
                      <button className="btn" onClick={this.handleClick}><FormattedMessage id="app.log_out" />
                        <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
                          <path d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878" />
                        </svg>
                      </button>
                    </div>
                  </li>
                </ul>
              </nav>
            )
            : (
              <nav>
                <ul className="auth_block" style={{ marginTop: marginTop + "px" }}>
                  <li className={classSignup}>
                    <Signup loginClick={this.loginClick} />
                    <div className="btn_left">
                      <button className="btn" onClick={this.loginClick}><FormattedMessage id="app.login" /></button>
                    </div>
                  </li>
                  <li className={classLogin}>
                    <Login signupClick={this.signupClick} />
                    <div className="btn_left">
                      <button className="btn" onClick={this.forgotClick}><FormattedMessage id="app.forgot_your_password" /></button>
                      <button className="btn" onClick={this.signupClick}><FormattedMessage id="app.create_account" /></button>
                    </div>
                  </li>
                  <li className={classForgot}>
                    <Forgot />
                    <div className="btn_left">
                      <button className="btn" onClick={this.loginClick}><FormattedMessage id="app.login" /></button>
                      <button className="btn" onClick={this.signupClick}><FormattedMessage id="app.create_account" /></button>
                    </div>
                  </li>
                </ul>
              </nav>
            )
          }
          <nav className="page_navigations">
            <ul>
              <li >
                {/* style={{ display: "none" }} */}
                <FormattedMessage id="app.home">{
                  msg => (<a className="href_" data-text={msg} href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.possibility_app">{
                  msg => (<a className="href_" data-text={msg} data-num="02" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.demo_app">{
                  msg => (<a className="href_" data-text={msg} data-num="03" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              {/* <li>
                <FormattedMessage id="app.shop">{
                  msg => (<a className="href_" data-text={msg} data-num="04" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li> */}
              <li>
                <FormattedMessage id="app.news">{
                  msg => (<a className="href_" data-text={msg} data-num="05" href={"#" + msg} >{msg}</a>)}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="app.contacts">{ msg => (<div className='link_animation' data-text={msg} onClick={this.contacts_togle.bind(this)}>{msg}</div>)}
                </FormattedMessage>
                <div className={"contacts_block" + (this.state.show_contacts ? " active" : "")}>
                  <p>support@opentube.ai</p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentUser
})
const mapDispatchToProps = dispatch => ({
  getLogoutUser: () => dispatch(getLogoutUser()),
  switchMenu: () => dispatch(switchMenu())
})
export default connect(mapStateToProps, mapDispatchToProps)(App);