const constants = (function () {
    function getLocale() {
        let path = window.location.pathname,
            regex = /^\/([a-z]+)/,
            defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'en';
        if (path === '/') return 'en';
        let str = path.match(regex)[1];
        return (['ru', 'uk'].includes(str)) ? str : defaultLocale;
    }
    function getPathLocale() {
        let currentLocale = getLocale();
        return currentLocale !== 'en' ? currentLocale + '/' : '';
    }
    return {
        location: window.location.protocol + "//" + window.location.host,
        baseLocale: '/:locale(en|ru|uk)?',
        locale: getLocale(),
        localePath: getPathLocale(),
    }
})();
export default constants;