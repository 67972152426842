import React, { Component } from 'react';
import constants from './Constants';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getLogoutUser, getDashboardFetch, postDashboardKeyAddFetch, getDashboardKeyRemoveDeviceFetch } from '../redux/actions';
import { useLottie } from "lottie-react";
import loading from "../images/load_lottie.json";
const LottieLoads = () => {
  const options = {
    animationData: loading,
    loop: true
  };
  const { View } = useLottie(options);
  return <>{View}</>;
};
class Dashboard extends Component {
  state = {
    addKey: "",
    addKeyMask: "•••• •••• ••••",
    keyLength: 14,
    addKeyError: false,
    serverError: false,
    serverErrorText: '',
    submitted: false,
    show_license: false,
    tab: 0,
    board: false
  }
  componentDidMount = () => {
    this.props.getDashboardFetch()
  }
  tab = i => {
    this.setState({ tab: i })
  }
  handleChange = event => {
    let key = event.target.value.replace(/[^\d.]/ig, "").replace(/(.{4})/g, '$1 ').trim();
    key = key.substr(0, 14)
    let keyMask = '•••• •••• ••••';
    let Mask = '';
    for (let i = 0; i < 14; i++) {
      Mask += (key?.[i]) ? key?.[i] : keyMask?.[i];
    }
    this.setState({
      addKeyError: false,
      serverError: false,
      serverErrorText: '',
      [event.target.name]: key,
      addKeyMask: Mask
    });
  }
  postDashboardKeyAddFetch = (key) => {
    const access_token = localStorage.access_token;
    if (access_token) {
      let formData = new FormData()
      formData.append("key", key)
      const fetchURL = process.env.REACT_APP_API_URL;
      return fetch(fetchURL + "/webApi/dashboard/key/add/", {
        method: "POST",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${access_token}`
        },
        body: formData
      })
        .then(resp => resp.json())
        .then(data => {
          if (data?.message === "Done") {
            this.props.getDashboardFetch()
            this.setState({ submitted: false, addKey: '', addKeyMask: "•••• •••• ••••" })
          } else {
            if (data?.errors?.key?.[0]) this.setState({ serverErrorText: data?.errors?.key?.[0] })
            this.setState({ submitted: false, serverError: true })
          }
        })
    }
  }
  handleSubmit = event => {
    event.preventDefault()
    if (this.state.addKey.length !== this.state.keyLength) {
      this.setState({ addKeyError: true })
      return false
    }
    this.setState({ submitted: true })
    this.postDashboardKeyAddFetch(this.state.addKey.replace(/[^\d.]/ig, ""))
  }
  handleRemove = (key, e) => {
    e.preventDefault()
    this.props.getDashboardKeyRemoveDeviceFetch(key)
  }
  showLicense = e => {
    this.setState({ show_license: !this.state.show_license })
  }
  showBoard = e => {
    this.setState({ board: !this.state.board })
  }
  logoutUser = () => {
    this.props.getLogoutUser()
  }
  renderRedirect = () => {
    if (!this.props.currentUser?.access_token) {
      return <Redirect to={`/${constants.localePath}`} />
    } else if (this.props.currentUser?.logout_time < new Date().getTime()) {
      this.props.getLogoutUser()
    }
  }
  render() {
    const dashboard = this.props.dashboard;
    let hide = (this.props.hide !== `/${constants.localePath}dashboard`)
    if (!this.props.currentUser?.access_token) {
      return <Redirect to={`/${constants.localePath}`} />
    }
    return (
      <section className={"works-section" + ((hide) ? " hiden_page" : " open_page")} id="dashboard" >
        <div className="top_bar">
          <div className="top_bar_nav">
            <Link className="top_bar_nav_logo" to={`/${constants.localePath}`}>
              <div id="logo" className="logo dashboard_logo">
                <img src="/images/opentube.svg" alt="opentube" />
                <div className="logo_inner">
                  <div className="image_z_logo image_z_logo_inner"></div>
                  <div className="image_z_logo"></div>
                </div>
              </div>
            </Link>
            <div className={"board" + ((this.state.board) ? " active" : "")} onClick={this.showBoard.bind(this)}>
              <span>{localStorage.getItem("email")}</span>
              <ul className="board_inner">
                <Link to={`/${constants.localePath}`}><li className="user_icon"><FormattedMessage id="dashboard" /></li></Link>
                <li className="exit_icon" onClick={this.logoutUser.bind(this)}><FormattedMessage id="logout" /></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="works-tabs">
            <div className="works-tabs-header">
              <div className="works-tabs-title row">
                <div className={"works-tabs-title__item" + ((+this.state.tab === 0) ? " active" : "")} data-page="0" onClick={this.tab.bind(this, 0)}>
                  <img src="/images/Licenses.svg" width="34" height="28" alt="licenses" />
                  <FormattedMessage id="licenses" />
                </div>
                <div className={"works-tabs-title__item" + ((+this.state.tab === 1) ? " active" : "")} data-page="1" onClick={this.tab.bind(this, 1)}>
                  <img src="/images/purchase.svg" width="30" alt="purchase" />
                  <FormattedMessage id="purchases" />
                </div>
                <span className="link" id="add-btn-license" onClick={this.showLicense.bind(this)}><FormattedMessage id="add_licenses" /></span>
              </div>
            </div>
            <div className="works-tabs-box">
              <div className="works-tabs-box__item" >
                <form onSubmit={this.handleSubmit.bind(this)} className={'license_form ' + ((this.state.show_license) ? 'open' : 'close')}>
                  <div className="input-wrap">
                    <input
                      style={{ position: "absolute", zIndex: "1" }}
                      id="add_key"
                      type='text'
                      name='addKey'
                      placeholder='add key'
                      value={this.state.addKey}
                      onChange={this.handleChange.bind(this)}
                      onBlur={this.handleChange.bind(this)}
                      className={((this.state.addKeyError) ? "input_error" : "")}
                      autoComplete="off"
                    />
                    <p className="input_mask">{this.state.addKeyMask.split('').map((e, i) => <i key={"mask" + i} className={(e === "•") ? "spot" : ""}>{(e === "•") ? "8" : e}</i>)}</p>
                    {(this.state.addKeyError) ? <i><FormattedMessage id="enter_key" /></i> : ""}
                    {(this.state.serverError) ? <i><FormattedMessage id="server_error" />: {this.state.serverErrorText}</i> : ""}
                  </div>
                  <div className="btn-wrap">
                    <button type='submit' className={`btn ${this.state.submitted ? "submitted" : ""}`}><FormattedMessage id="app.enter" />
                      <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
                        <path d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878" />
                      </svg>
                    </button>
                  </div>
                </form>
                {(dashboard?.message) ?
                  <div className="table">
                    <div className="table-row">
                      <div className="col">{dashboard?.message}</div>
                    </div>
                  </div>
                  :
                  ((this.state.tab === 0) ? (
                    (Array.isArray(dashboard?.keys)) ?
                      (dashboard.keys && dashboard.keys.length > 0) ?
                        dashboard.keys.map((item, i) =>
                          <div key={"keys" + i} className="table">
                            <div className="table-row">
                              <div className="col">
                                <b><FormattedMessage id="device" /></b>
                                {Array.isArray(item?.device) ?
                                  <p>{(item?.device?.[0] && item?.device?.[0] !== null) ? item?.device?.[0]?.deviceName : <FormattedMessage id="no_device" />}</p> :
                                  <p>{(item?.device && item?.device !== null) ? item?.device?.deviceName : <FormattedMessage id="no_device" />}</p>
                                }
                              </div>
                              <div className="col">
                                <b><FormattedMessage id="licenses" /></b>
                                <p>{item?.key}</p>
                              </div>
                              <div className="col">
                                <b><FormattedMessage id="activation_date" /></b>
                                <p>{(item?.activate_time && item?.activate_time !== null) ? item?.activate_time : "-"}</p>
                              </div>
                              <div className="col">
                                <b><FormattedMessage id="expiry_date" /></b>
                                <p>{(item?.end_time && item?.end_time !== null) ? item?.end_time : "-"}</p>
                              </div>
                            </div>
                            {(item?.active) ?
                              <div className="col-btn">
                                <a href={"#" + item?.id} data-key={item?.id} className="btn" onClick={this.handleRemove.bind(this, item?.id)}><FormattedMessage id="remove_device" /></a>
                              </div>
                              : ''}
                          </div>
                        ) : <div className="table">
                          <div className="table-row">
                            <div className="col"><FormattedMessage id="no_purchases" /></div>
                          </div>
                        </div>
                      : <div className="loader"><LottieLoads /></div>
                  )
                    : (
                      (Array.isArray(dashboard?.purchases)) ?
                        (dashboard.purchases && dashboard.purchases.length > 0) ?
                          dashboard.purchases.map((item, i) =>
                            <div key={"purchases" + i} className="table"></div>)
                          : <div className="table">
                            <div className="table-row">
                              <div className="col"><FormattedMessage id="no_purchases" /></div>
                            </div>
                          </div>
                        : <div className="loader"><LottieLoads /></div>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentUser,
  dashboard: state.dashboard,
  serverError: state.serverError
})
const mapDispatchToProps = dispatch => ({
  getLogoutUser: () => dispatch(getLogoutUser()),
  getDashboardFetch: () => dispatch(getDashboardFetch()),
  postDashboardKeyAddFetch: (key) => dispatch(postDashboardKeyAddFetch(key)),
  getDashboardKeyRemoveDeviceFetch: (key) => dispatch(getDashboardKeyRemoveDeviceFetch(key))
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
