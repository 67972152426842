document.addEventListener('DOMContentLoaded', function () {
    if (process.env.NODE_ENV === 'development' && !(localStorage.getItem('staginLogin') === "open" && localStorage.getItem('staginPass') === "open")) return false;
    const body = document.querySelector('body');
    function start() {
        body.classList.add('start');
    }
    let e = document.querySelector(".image_z");
    let activeRotate = true;
    function clicks(ev) {
        if (activeRotate) {
            activeRotate = false;
            return false;
        }
        activeRotate = true;
        let i = (ev) ? "right" : "left";
        body.classList.remove("rotate_right", "rotate_left");
        setTimeout(() => body.classList.add("rotate_" + i), 10);
        setTimeout(() => activeRotate = false, 500);
    }
    const menu = document.getElementById("menu_icon"),
        main = document.querySelector(".main"),
        root = document.getElementById("root"),
        rootMenu = document.querySelector(".root_menu"),
        sliderArrowNext = document.querySelector(".slider-arrow-next");
    let menuArrow = sliderArrowNext.querySelectorAll("div");
    function changeMenuArrow(i) {
        menuArrow.forEach((e, i) => {
            e.classList.remove("active");
        });
        menuArrow[i].classList.add("active");
    }
    function menuClick() {
        const height = main.offsetHeight
        const e_height = e.offsetHeight
        if (menu.classList.contains('menu_active')) {
            menu.querySelectorAll('.button-play').forEach((e, i) => {
                e.beginElement();
            });
            main.style.height = null;
            e.style.height = null;
            e.style.width = null;
            menu.classList.remove('menu_active');
        } else {
            menu.querySelectorAll('.button-pause').forEach((e, i) => {
                e.beginElement();
            });
            main.style.height = height + "px";
            e.style.height = e_height + "px";
            e.style.width = e_height + "px";
            menu.classList.add('menu_active');
        }
        return false;
    };
    function menuBar() {
        menuClick();
        main.classList.toggle("menu_active");
        root.classList.toggle("menu_active");
        rootMenu.classList.toggle("menu_active");
    }
    menu.addEventListener("click", menuBar);
    main.addEventListener("click", function (e) { if (this.classList.contains("menu_active")) { menuBar(); } })
    document.getElementById("logo").addEventListener("click", (e) => { paginatorChange(e, 0); if (main.classList.contains("menu_active")) { menuBar(); } })
    let paginator = document.querySelector(".paginator"),
        paginators = paginator.querySelectorAll("div"),
        paginatorBg = paginator.querySelector("i"),
        activeTab = 0,
        tabs = paginators.length - 1;
    let paginatorWith = (paginator.offsetWidth / paginators.length);
    paginatorBg.style.width = paginatorWith + 'px';
    function paginatorChange(e, el) {
        clicks(activeTab <= el);
        if (el < 0) el = tabs;
        if (el > tabs) el = 0;
        let menuArrowN = 1 + el;
        if (menuArrowN < 0) menuArrowN = tabs;
        if (menuArrowN > tabs) menuArrowN = 0;
        body.classList.remove('tab_' + activeTab);
        setTimeout(() => body.classList.add("tab_" + el), 30);
        changeMenuArrow(menuArrowN);
        paginators[activeTab].classList.remove("active");
        activeTab = el;
        paginators[el].classList.add("active");
        paginatorBg.style.width = (paginatorWith * (1 + el)) + 'px';
    }
    paginators.forEach((e, i) => {
        e.addEventListener("click", (e) => { if (i !== activeTab) paginatorChange(e, i) })
    });
    var page_navigations = document.querySelectorAll(".page_navigations .href_");
    page_navigations.forEach((e, i) => {
        e.addEventListener("click", (e) => { if (i !== activeTab) { menuBar(); paginatorChange(e, i); } })
    });
    sliderArrowNext.addEventListener("click", (e) => { paginatorChange(e, 1 + activeTab) });
    document.onkeydown = checkKey;
    function checkKey(e) {
        e = e || window.event;
        switch (e.keyCode) {
            case 38:
                break
            case 40:
                break
            case 37:
                paginatorChange(e, activeTab - 1);
                break
            case 39:
                paginatorChange(e, activeTab + 1);
                break
            default:
                break
        }
    }
    var xDown = null;
    var yDown = null;
    function handleTouchStart(evt) {
        xDown = evt.touches[0].clientX;
        yDown = evt.touches[0].clientY;
    }
    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }
        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                paginatorChange(e, activeTab + 1);
            } else {
                paginatorChange(e, activeTab - 1);
            }
        }
        xDown = null;
        yDown = null;
    }
    main.addEventListener('touchstart', handleTouchStart, false);
    main.addEventListener('touchmove', handleTouchMove, false);
    paginatorChange(e, activeTab);
    const howItWork = document.querySelector(".how_it_work_content");
    const demoPopap = document.querySelector(".demo_popap");
    const demoPopapLeft = demoPopap.querySelector(".left_arrow");
    const demoPopapRight = demoPopap.querySelector(".right_arrow");
    const howItWorkSlides = howItWork.querySelectorAll(".how_it_work_content_phone_figure");
    const howItWorkSlidesCount = howItWorkSlides.length - 1;
    let howItWorkSlide = 0;
    function demoSlides(i) {
        if (i < 0 || i > howItWorkSlidesCount) return false;
        demoPopapLeft.classList.add("active");
        demoPopapRight.classList.add("active");
        howItWork.classList.remove("how_" + howItWorkSlide);
        if (i <= 0) demoPopapLeft.classList.remove("active");
        if (i >= howItWorkSlidesCount) demoPopapRight.classList.remove("active");
        howItWorkSlides[howItWorkSlide].classList.remove("active");
        howItWorkSlides[i].classList.add("active");
        howItWorkSlide = i;
        howItWork.classList.add("how_" + i);
    }
    demoPopapLeft.addEventListener("click", () => { demoSlides(howItWorkSlide - 1); })
    demoPopapRight.addEventListener("click", () => { demoSlides(howItWorkSlide + 1); })

    const newsPopap = document.querySelector(".news_popap");
    const newsBlock = document.querySelectorAll(".news_block");
    const newsBlockFigure = document.querySelectorAll(".news_block_figure");
    const newsBlockArticle = document.querySelectorAll(".news_block_article");
    function showNewsBlock(i) {
        newsBlock[i].classList.toggle("active");
        var panel = newsBlock[i];
        var figure = newsBlockFigure[i];
        var article = newsBlockArticle[i];
        if (!panel.closest(".active")) {
            panel.style.transform = "translate3d(0,0,0)";
            panel.style.marginBottom = "10px"
            panel.style.minHeight = "300px";
            figure.style.minHeight = "150px";
            article.style.minHeight = "5.2em";
            newsPopap.style.overflow = "overlay";
        } else {
            panel.style.transform = "translate3d(0," + (panel.offsetTop - newsPopap.scrollTop) * -1 + "px,0)";
            panel.style.marginBottom = (panel.offsetTop - newsPopap.scrollTop - 10) * -1 + "px";
            figure.style.minHeight = figure.scrollHeight + "px";
            article.style.minHeight = article.scrollHeight + "px";
            panel.style.minHeight = newsPopap.clientHeight + "px";
            newsPopap.style.overflow = "hidden";
        }
    }
    newsBlock.forEach((e, i) => {
        e.addEventListener("click", (e) => { showNewsBlock(i) });
    });
    const onMouseWheelBlock = document.querySelector(".mouse_wheel_block");
    let onMouseWheelIsDone = true;
    function onMouseWheel(e) {
        onMouseWheelIsDone = false;
        e.deltaY > 0
            ? paginatorChange(e, activeTab + 1)
            : paginatorChange(e, activeTab - 1)
        setTimeout(() => {
            onMouseWheelIsDone = true;
        }, 1500);
    }
    function scrollPage() {
        onMouseWheelBlock.addEventListener('mousewheel', (e) => { if (onMouseWheelIsDone) onMouseWheel(e) });
    }
    const typedWrap = document.querySelector(".typed-wrap");
    let descriptions = typedWrap.querySelectorAll("div");
    const description = descriptions.length - 1;
    let timer = 0;
    let typedWrapDelay = 1000;
    function typeWriter(timers, e, txt, s = 4, i = s) {
        if (timer !== timers) return false;
        if (i < txt.length) {
            let a = (i < txt.length - 1) ? '_' : '<span className="typed-cursor typed-cursor--blink">_</span>';
            let txti = txt.substring(0, s) + txt.substring(s, 1 + i) + a;
            e.innerHTML = txti;
            i++;
            setTimeout(() => typeWriter(timers, e, txt, s, i), typedWrapDelay / (txt.length - s));
        } else setTimeout(nextTypeWriter, typedWrapDelay * 2);
    }
    function nextTypeWriter() {
        typedWrap.innerHTML = '<span className="typed-cursor typed-cursor--blink">_</span>';
        timer = (description === timer) ? 0 : timer + 1;
        setTimeout(() => { typeWriter(timer, typedWrap, descriptions[timer]?.innerText, 0) }, typedWrapDelay);
    }
    if (window.innerWidth > 1024) { nextTypeWriter(); scrollPage(); };
    start();
});
(function init100vh() {
    function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    window.addEventListener('resize', setHeight);
})();